import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react'
import PageFragment from '../../components/PageFragment'
import Headline from '../../components/Headline'
import { FormattedMessage } from 'react-intl'
import { Grid } from '@material-ui/core'
import { createSelector } from 'reselect'
import { subsidyFilesErrorSelector, subsidyFilesLoadingSelector, subsidyFilesSelector } from './selectors'
import { connect } from 'react-redux'
import UnknownErrorFragment from '../../components/UnknownErrorFragment'
import Loader from '../../components/Loader'
import { getSubsidyFilesByYear } from './actions'
import LabelledText from '../../components/LabelledText'
import DownloadLink from '../../components/DownloadLink'
import { YearContext } from '../../global/year/YearContext'
import YearFilter from '../../global/year/YearFilter'
import { Dict } from '../../global'
import {FILTER_MODE} from '../../appConst';

const FILTERS_NAME = 'SubsidyFilesYearFilters'

const FILE_NAMES_ELIANCE = [
    'detailRacialBovin',
    'detailTerritorialBovin',
    'detailCaprin',
    'compensationComparison',
]

const FILE_NAMES_FAM_MINISTRY = [
    'etatsRecapBovins',
    'etatsRecapCaprins',
    'arrondissements',
    'keyNumbersCTI',
]

type SubsidyFilters = {
    year: number
}

type File = {
    id: number
    name: string
}

type SubsidyFilesPageProps = {
    files: Dict<File>,
    loading: boolean
    error: boolean,
    getSubsidyFilesByYear: (year: number) => void
}

const SubsidyFilesPage: FC<SubsidyFilesPageProps> = (props) => {
    const { files, loading, error, getSubsidyFilesByYear } = props
    const { currentYearWithParameters: currentYear } = useContext(YearContext)
    const [ filters, setFilters ] = useState<SubsidyFilters>({ year: currentYear })

    useEffect(() => {
        getSubsidyFilesByYear(filters.year)
    }, [getSubsidyFilesByYear, filters])

    const handleSearch = useCallback((values: SubsidyFilters) => {
        setFilters(values)
    }, [setFilters])

    const getDownloadHref = useCallback((fileName: string): string => {
        return `/api/admin/subsidy_files/${files[fileName]?.id}`
    }, [ files ])

    const getDownloadName = useCallback((fileName: string): string => {
        return files[fileName]?.name || ''
    }, [ files ])

    return (
        <PageFragment>
            <Headline>
                <FormattedMessage id="subsidy_files.subtitle"/>
            </Headline>
            <YearFilter
                form={FILTERS_NAME}
                disabled={loading}
                initialValues={filters}
                onSubmit={handleSearch}
                mode={FILTER_MODE.WITH_FILTER}
            />
            {
                loading ? <Loader/> : (
                    error ?
                        <UnknownErrorFragment
                            message={<FormattedMessage id="subsidy_files.errors.empty"/>}
                        /> : files && (
                            <Fragment>
                                <Headline>
                                    <FormattedMessage id="subsidy_files.eliance.title"/>
                                </Headline>
                                <Grid container>
                                    {
                                        FILE_NAMES_ELIANCE.filter(file => getDownloadName(file)).map((file) => (
                                            <Grid key={file} item xs={6}>
                                                <LabelledText
                                                    label={<FormattedMessage id={`subsidy_files.${file}`}/>}
                                                    content={<DownloadLink href={getDownloadHref(file)}>{getDownloadName(file)}</DownloadLink>}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                                <Headline>
                                    <FormattedMessage id="subsidy_files.fam_minisry.title"/>
                                </Headline>
                                <Grid container>
                                    {
                                        FILE_NAMES_FAM_MINISTRY.map((file) => (
                                            <Grid key={file} item xs={6}>
                                                <LabelledText
                                                    label={<FormattedMessage id={`subsidy_files.${file}`}/>}
                                                    content={<DownloadLink href={getDownloadHref(file)}>{getDownloadName(file)}</DownloadLink>}
                                                />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Fragment>
                        )
                )
            }
        </PageFragment>
    )
}

const mapStateToProps = createSelector([
    subsidyFilesSelector,
    subsidyFilesLoadingSelector,
    subsidyFilesErrorSelector
], (files, loading, error) => ({
    files,
    loading,
    error
}))

const mappedActions = {
    getSubsidyFilesByYear
}

export default connect(
    mapStateToProps,
    mappedActions
)(SubsidyFilesPage)
